import React, { useEffect } from "react"
import Layout from "../components/layout"
import Link from "gatsby-link"
import Breadcrumbs from "./breadcrumbs"

function ViewAll({ allPosts, categoryName, breadcrumbLinks }) {
  const handleClick = () => {
    if (typeof window !== undefined) {
      window.location.reload()
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // console.log(allPosts[categoryName])

  return (
    <div>
      <section className="container max-w-5xl mx-auto px-6 py-0 md:py-20 md:pb-0">
        <div className="mx-auto px-6 mb-20">
          <div className="text-center">
            <h1 className="section-title">
              <span className="text-orange">Browse</span> Resources by
              Categories
            </h1>
          </div>
        </div>

        <div className="vc-doc-content">
          <Breadcrumbs breadcrumbLinks={breadcrumbLinks} />
        </div>
      </section>
      <div className="max-w-5xl px-6 mx-auto md:pb-20">
        <h3 className="text-2xl md:text-4xl font-bold text-gray-500 mb-3">
          {categoryName}
        </h3>
        {allPosts[categoryName].reverse().map((e, key) => {
          return (
            <>
              <Link
                to={`${e.data.uri}`}
                className="text-gray-500 block text-sm md:text-base"
                key={key}
              >
                {e.data.title}
              </Link>
            </>
          )
        })}
        <p
          onClick={handleClick}
          className="block mt-4 text-gray-500 text-sm md:text-base font-bold underline cursor-pointer"
        >
          Go Back
        </p>
      </div>
    </div>
  )
}

export default ViewAll
